import React from 'react';
import "./Booking.css";

function Booking() {

  return (

    <div className="Booking">
      <a className="BookingBtn" href='https://www.fresha.com/a/thefacebyanahit-glendale-sola-salon-studios-225-west-broadway-ybp3pylx/all-offer?menu=true&pId=1351533' aria-label='Booking an apoitment via Fresha' target='_blank' rel="noreferrer">Book Now</a>
    </div>
  )
}

export default Booking