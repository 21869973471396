import React from 'react';
import './Logo.css';
import theFacebyAnahit from "../../../images/logotransparrent03.png"

function Logo() {

  return (
    <div className='Logo'>
      <a href="/"><img src={theFacebyAnahit} alt="The Face By Anahit Logo"/></a>
    </div>
  )
}

export default Logo