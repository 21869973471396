import React, { useEffect } from 'react';

const SchemaMarkup = () => {
  useEffect(() => {

    const imageUrl = process.env.REACT_APP_THE_FACE_BY_ANAHIT; 

    const schemaData = {
      "@context": "https://schema.org",
      "@type": "BeautySalon",
      "name": "TheFaceByAnahit",
      "description": "Professional makeup artist offering bridal makeup, special occasion makeup, and personalized beauty services in Glendale, CA.",
      "jobTitle": "Makeup Artist",
      "openingHours": "Mo,Tu,We,Th,Fr,Sa,Su 06:00-17:00",
      "telephone": "7473065251",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "225 W Broadway # 120",
        "addressLocality": "Glendale",
        "addressRegion": "CA",
        "postalCode": "91204",
        "addressCountry": "US"
      },
      "priceRange": "150-400$$",
      "paymentAccepted": "Cash, Credit Card, Debit Card",
      "image": imageUrl,
      "email": "mailto:danghyananahit@gmail.com",
    };

    // Create the JSON-LD script element
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.innerHTML = JSON.stringify(schemaData);

    // Append the script to the head
    document.head.appendChild(script);

    // Cleanup function to remove the script on component unmount
    return () => {
      document.head.removeChild(script);
    };
  }, []); // Empty dependency array to run only on mount and unmount

  return null; // This component doesn't render anything visible
};

export default SchemaMarkup;
