import React from 'react';
import './Main.css';
import Insta from '../Insta/Insta';
import Booking from '../Booking/Booking';

function Main() {

  return (

    <main>
      <Insta />
      <Booking />
    </main>
  )
}

export default Main