import React from 'react';
import "./Insta.css";
import instaLogo from "../../../images/instagram.png";

function Insta() {

  return (
    <div className='Insta'>
      <a href='https://www.instagram.com/thefacebyanahit' aria-label='TheFaceByAnahit instagram page' target='_blank' rel="noreferrer"><img className='InstaLogo' src={instaLogo} alt="instagram"/></a>
    </div>
  )
}

export default Insta