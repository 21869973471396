import React from 'react';
import './Header.css';
import Logo from '../Logo/Logo';

function Header() {

  return (

    <header>
      <Logo />
      <h1 className="purpose">The Face By Anahit - Makeup Artist</h1>

      <h2 className="purpose">Bridal Makeup</h2>
      <h3 className="purpose">Bridal Trials</h3>
      <p className="purpose">Customized looks to match your wedding theme. Expert **Bridal Makeup** by a **Los Angeles Makeup Artist** in a professional **Bridal Studio**.</p>
      <h4 className="purpose">Schedule Your Trial</h4>
      <p className="purpose">Contact us to book a trial session at our **Makeup Studio**.</p>
      <h3 className="purpose">Destination Weddings</h3>
      <p className="purpose">Expert makeup services for weddings abroad, provided by a **Celebrity Makeup Artist**.</p>
      <h3 className="purpose">Bridal Party Makeup</h3>
      <p className="purpose">Makeup for bridesmaids and family members in a **Hairstudio** that offers **Bridal Hair** services.</p>

      <h2 className="purpose">Special Occasion Makeup</h2>
      <h3 className="purpose">Birthday Makeup Packages</h3>
      <p className="purpose">Tailored makeup for your special day, from a renowned **Los Angeles Makeup Artist**.</p>
      <h3 className="purpose">Prom and Homecoming Makeup</h3>
      <p className="purpose">Look stunning for your big night with professional **Makeup** services.</p>

      <h2 className="purpose">Client Testimonials</h2>
      <h3 className="purpose">See What Our Brides Are Saying</h3>
      <p className="purpose">"Anahit made me feel beautiful on my special day with her **Bridal Makeup** expertise!"</p>
      <h3 className="purpose">What Clients Say About Special Occasion Makeup</h3>
      <p className="purpose">"The makeup was flawless and lasted all night! A top choice for any **Makeup Studio**!"</p>

      <h2 className="purpose">Regular Makeup</h2>
      <h3 className="purpose">Everyday Makeup Services</h3>
      <p className="purpose">Get your everyday look perfected by a professional **Makeup Artist**.</p>

      <h2 className="purpose">Eyebrows Lamination</h2>
      <h3 className="purpose">What is Eyebrow Lamination?</h3>
      <p className="purpose">A treatment that provides a fuller and more uniform look, ideal for **Bridal Hair** styling.</p>

      <h2 className="purpose">Eyebrows Shaping</h2>
      <h3 className="purpose">Techniques We Use</h3>
      <p className="purpose">Waxing, tweezing, and threading options available at our **Hairstudio**.</p>

      <h2 className="purpose">Eyelashes</h2>
      <h3 className="purpose">Eyelash Extensions</h3>
      <p className="purpose">Get longer, fuller lashes for a dramatic look by a **Celebrity Makeup Artist**.</p>
      <h3 className="purpose">Lash Lifts</h3>
      <p className="purpose">Enhance your natural lashes for a beautiful curl, perfect for any occasion.</p>

    </header>
  )
}

export default Header