import React from 'react';
import './Footer.css';

function Footer() {

  return (

    <footer>
      <h5>Created By <a href="https://www.tieg.run/">TIEGRUN</a></h5>
    </footer>

  )
}

export default Footer